* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
}
h1 {
  font-family: Comfortaa;
  font-size: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 1;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  font-size: calc(100vw / 120);
}
body {
  background-color: #f7f7f7;
  height: 100%;
  width: 100%;
  font-family: Roboto;
  color: #333333;
}
a {
  text-decoration: none;
}
#root {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  min-width: 1280px;
  position: relative;
  overflow: hidden;
}
.MuiFilledInput-root.Mui-error {
  background-color: rgba(244, 67, 54, 0.09);
}
.Mui-error .MuiFormHelperText-root {
  color: #f44336;
}

@media only screen and (min-width: 1920px) {
  html {
    font-size: 16px;
  }
  #root {
    width: 100%;
  }
}
@media only screen and (max-width: 1439px) {
  html {
    font-size: 12px;
  }
  #root {
    width: 1440px;
  }
}
