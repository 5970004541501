html .MuiFilledInput-input {
  padding: 1rem;
}

html .MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ka-wrapper {
  height: 100%;
}

.ka-content {
  height: 100%;
}

.MuiTooltip-arrow {
  color: #fff;
}
