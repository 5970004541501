$text: #333333;
$text-grey: #9fa7ae;
$light-red: #fa9090;
$main-red: #e16162;
$dark-red: #ac4040;
$light-yellow: #fdd290;
$main-yellow: #f9bc60;
$dark-yellow: #f09500;
$bright-yellow: #fdee21;
$light-purple: var(--mainLightThemeColor, #c3cdfc);
$main-purple: var(--mainThemeColor, #8fa0f5);
$dark-purple: var(--mainDarkThemeColor, #5c6bb5);
$main-green: green;
$dark-green: #006400;
$light-grey: #f7f7f7;
$headline-grey: #001e1d;
$color11: #2c2c2c;
$color12: #5a5a5a;
$bg-grey: #f1f1f1;
$border-grey: #cccccc;
$white: #ffffff;

$rbwmspace1: 1px;
$rbwmspace4: 0.25rem;
$rbwmspace8: 0.5rem;
$rbwmspace12: 0.75rem;
$rbwmspace16: 1rem;
$rbwmspace20: 1.25rem;
$rbwmspace24: 1.5rem;
$rbwmspace28: 1.75rem;
$rbwmspace32: 2rem;
$rbwmspace36: 2.25rem;
$rbwmspace40: 2.5rem;
$rbwmspace44: 2.75rem;

$fontSize1: 2.5rem;
$fontSize2: 1.75rem;
$fontSize3: 1.25rem;
$fontSize4: 1rem;
$fontSize5: 0.875rem;
$fontSize6: 0.75rem;

$lighter-font-weight: 200;
$light-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 600;
$bold-font-weight: 800;

$sessionBarHeight: 3rem;

$paper-shadow: rgba(0, 0, 0, 0.24) 2px 2px 8px;
$card-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

.visualisation {
  width: 100%;
  height: 100%;
  position: relative;
}

.dataContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $rbwmspace44;
}

.title {
  color: $dark-purple;
  margin-bottom: $rbwmspace44;

  .description {
    color: $color12;
    font-size: $fontSize4;
    font-family: Roboto;
    margin-top: $rbwmspace8;
  }
}

.uploadContainer {
  margin-top: $rbwmspace44;
  display: flex;
}
.leftContainer {
  margin-right: $rbwmspace44;
}

.fileTemplates {
  display: flex;
  margin-top: $rbwmspace12;

  .fileTemplateItem {
    width: 250px;
    padding: $rbwmspace20;
    // height: 150px;
    border: 1px dashed $text-grey;
    margin-right: $rbwmspace44;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    svg {
      margin-bottom: $rbwmspace8;
      font-size: 60px;
    }

    .fileName {
      font-size: 12px;
    }
  }
}

.processUpload {
  margin-top: $rbwmspace44;
}

.uploadFileArea {
  border: 2px dashed $main-purple;
  padding: 20px;
  text-align: center;
  width: 960px;
  height: 300px;
  .uploadFileMask {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      font-size: 60px;
    }
    p {
      margin: $rbwmspace8;
      font-size: $fontSize3;
      &.desc {
        color: $text-grey;
        font-size: $fontSize4;
      }
    }
  }
  .fileSelected {
    display: flex;
    align-items: center;
    svg {
      width: 30px;
      margin-right: $rbwmspace20;
    }
  }
}
.batchRun {
  display: flex;
  justify-content: flex-end;
  margin-top: $rbwmspace20;
}
.batchTaskList {
  margin-top: $rbwmspace12;
  width: 320px;
  height: 100%;
  .fileStatusIcon {
    display: flex;
    svg {
      width: 2rem;
      height: 2rem;
      margin-right: $rbwmspace8;
    }
  }
  .empty {
    font-size: $fontSize4;
    color: $text-grey;
  }
}
